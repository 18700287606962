import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

const CalculatorDetails = ({ data }) => {
  var calculator = data.allSitePage.edges[0].node.context
  return (
    <Layout>
      <SEO
        title={calculator.name + " | Oxygen - Home Loans Made Simple"}
        description={calculator.description}
      />
      <Navbar />
      <PageBanner
        pageTitle={calculator.name}
        pageSubTitle={calculator.description}
      />
      <div className="container calc-iframe pb-5">
        <div className="loader"></div>
        <iframe
          id={calculator.calcid}
          src={calculator.iframe}
          frameborder="0"
          scrolling="no"
          title="calc-iframe"
        ></iframe>
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}
export default CalculatorDetails

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            name
            description
            iframe
            calcid
          }
        }
      }
    }
  }
`
